import { useState } from "react";
import { useFloating, offset, flip, shift } from "@floating-ui/react";
import PublishDropdownButtonContent from "~/components/general/PublishDropdownButtonContent";

interface Props {
  communityNameForPublish?: string;
}

const PublishDropdownButton = ({ communityNameForPublish }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { x, y, reference, floating, strategy } = useFloating({
    middleware: [offset(10), flip(), shift()],
    placement: "bottom-end",
    strategy: "fixed"
  });

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className="hidden rounded-full overflow-hidden pc:flex pc:w-full pc:px-4 pc:mt-3">
      <button
        ref={reference}
        onClick={handleClick}
        className="flex flex-1 m-0 p-0 pc:mr-0 pc:px-4 pc:py-3 text-center justify-center pc:justify-center rounded-3xl text-pri font-medium pc:hover:bg-acc/[.85] pc:bg-acc pc:dark:hover:bg-acc/[0.85] pc:dark:bg-acc"
      >
        <span className="hidden pc:flex text-[17px]">Publish</span>
      </button>
      {isOpen && (
        <div
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0
          }}
          className="mt-2 w-48
            flex items-center justify-center sm:justify-start 
            bg-pri dark:bg-pri-d
            select-none gap-x-5 pc:py-[11px] border-pri dark:border-pri-d border 
            cursor-pointer 
            pc:pl-4 pc:mr-4 rounded-lg 
            flex-1 !py-2.5 !mr-0  outline-none"
        >
          <PublishDropdownButtonContent
            communityNameForPublish={communityNameForPublish}
            setIsOpen={setIsOpen}
          />
        </div>
      )}
    </div>
  );
};

export default PublishDropdownButton;
