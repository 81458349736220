import { Links, Meta, useLocation, useSubmit } from "@remix-run/react";
import { useEffect } from "react";
import { isSSR } from "~/utils/ssr";
import { Simulate } from "react-dom/test-utils";
import { useAppStore } from "~/store";
import { AccountSettings } from "~/store/settings";

export function Head({ settings }: { settings: AccountSettings }) {
  const location = useLocation();
  const activeAccount = useAppStore(store => store.account.activeAccount);
  const isAuthPage =
    location.pathname === "/login" || location?.pathname?.startsWith("/signup");

  const isClaimKeysPage = location?.pathname?.startsWith("/claim-keys");

  useEffect(() => {
    const handleLoad = () => {
      {
        const script = document.createElement("script");
        script.src = "https://unpkg.com/@hiveio/dhive@1.2.8/dist/dhive.js";
        script.async = true;
        script.defer = true;

        // Append the script to the document body
        document.body.appendChild(script);
      }
      {
        const script = document.createElement("script");
        script.src = "https://api.inleo.io/latest.js";
        script.async = true;
        script.defer = true;

        // Append the script to the document body
        document.body.appendChild(script);
      }
      // Create the script element only after the page has fully loaded
    };

    setTimeout(
      () => {
        if (!isSSR() && document.readyState !== "loading") {
          handleLoad();
        }
      },
      isAuthPage || isClaimKeysPage ? 1 : 2500
    );
  }, []);

  useEffect(() => {
    // const script = document.createElement("script");
    // script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5523131112113684`;
    // script.async = true;
    // script.defer = true;
    // document.body.appendChild(script);

    // title
    const title = document.createElement("title");
    title.innerText = "InLeo";
    document.head.appendChild(title);

    // meta tags
    const metas: Record<string, string>[] = [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,maximum-scale=1,user-scaleable=yes,viewport-fit=cover"
      },
      {
        name: "theme-color",
        content: settings?.theme === "Dark" ? "#000" : "#fff"
      },
      {
        name: "description",
        content:
          "InLeo is a blockchain-based social media community for Crypto & Finance content creators. Our tokenized blogging platform allows users and creators to engage and share content on the blockchain while earning LEO token rewards."
      }
    ];

    metas.forEach(meta => {
      const m = document.createElement("meta");

      Object.entries(meta).forEach(([key, value]) => {
        m.setAttribute(key, value);
      });

      document.head.appendChild(m);
    });
  }, [settings]);

  return (
    <head>
      <Meta />
      <Links />
    </head>
  );
}
