import { useCallback, useEffect, useState } from "react";
import { useAppStore } from "~/store";
import { type AccountKeys } from "~/store/account";
import parseAsset from "~/utils/asset";
import { cache } from "~/utils/cache";
import { getCookie } from "~/utils/cookie";
import { getDynamicGlobalProperties, getFeedHistory } from "~/utils/hive";
import { createSignToken } from "~/utils/keychain";
import { leocache } from "~/utils/leocache";

export function useRouteClientLoader() {
  const [
    activeAccount,
    setTokenPrices,
    setThreadcasts,
    setKeys,
    setMedian,
    setDynamicGlobalProperties
  ] = useAppStore(store => [
    store.account.activeAccount,
    store.wallet.setTokenPrices,
    store.discussion.setThreadcasts,
    store.account.setKeys,
    store.wallet.setMedian,
    store.wallet.setDynamicGlobalProperties
  ]);

  const [accountKeys, setAccountKeys] = useState<AccountKeys>({
    signature: null,
    publicKey: null,
    proxy: null
  });

  const updateKeys = useCallback((key: keyof AccountKeys, value: string) => {
    setAccountKeys(current => ({
      ...current,
      [key]: value
    }));
  }, []);

  // set premium state, token prices and threadcasts
  useEffect(() => {
    void (async function () {
      const [tokenPrices, threadcasts] = await Promise.all([
        cache.getTokenPrices(),
        leocache.getLatestThreadcasts()
      ]);

      setTokenPrices(tokenPrices);
      setThreadcasts(threadcasts);
    })();
  }, []);

  // global config
  useEffect(() => {
    void (async function () {
      const [feedHistory, dynamicGlobalProperties] = await Promise.all([
        getFeedHistory(),
        getDynamicGlobalProperties()
      ]);

      setMedian({
        base: parseAsset(feedHistory.current_median_history.base).amount,
        quote: parseAsset(feedHistory.current_median_history.quote).amount
      });
      setDynamicGlobalProperties(dynamicGlobalProperties);
    })();
  }, []);

  // set signature
  useEffect(() => {
    if (!activeAccount) return;

    setTimeout(() => {
      const _signature = window.localStorage.getItem("activeAccount");
      const { auth, proxy } = getCookie("__session") || { auth: "", proxy: "" };

      if (_signature !== undefined && _signature !== null) {
        return updateKeys("signature", _signature);
      }

      if (proxy === "hivesigner") {
        try {
          const authDetails = JSON.parse(atob(auth));
          const { signatures } = authDetails;
          const signature = signatures?.at(0) || "";

          updateKeys("signature", signature);
          updateKeys("proxy", "hivesigner");
        } catch {
          console.log("Can't find any signature draft saving is disabled!");
        }
      } else if (proxy === "keychain") {
        void (async function () {
          createSignToken(activeAccount.name).catch(() =>
            updateKeys("signature", _signature!)
          );

          const newSignature = await createSignToken(activeAccount.name);

          updateKeys("signature", newSignature);
          updateKeys("proxy", "keychain");

          window.localStorage.setItem("activeAccount", newSignature);
        })();
      } else {
        updateKeys("signature", _signature!);
      }
    }, 100);
  }, [activeAccount, updateKeys]);

  // set public keys
  useEffect(() => {
    if (!activeAccount) return;
    const { proxy } = getCookie("__session");

    const key =
      proxy === "hivesigner"
        ? activeAccount.active?.key_auths?.[0]?.[0]
        : activeAccount.posting?.key_auths?.[0]?.[0];

    updateKeys("publicKey", key as string);
  }, [activeAccount, updateKeys]);

  useEffect(() => {
    setKeys(accountKeys);
  }, [accountKeys]);
}
