import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import DisplayName from "~/components/format/DisplayName";
import { Link } from "@remix-run/react";
import { getAvatar } from "~/utils/hive";

export function LiveNotification({
  notification,
  authorFromMesssage,
  voteAmount,
  cleanMessage,
  mouseEnter
}: {
  notification: any;
  authorFromMesssage: string;
  voteAmount: string;
  cleanMessage: string;
  mouseEnter: () => void;
}) {
  return (
    <div className="select-none bg-pri dark:bg-pri-d" onClick={mouseEnter}>
      <div className="absolute p-2 right-4 top-4 h-10 w-10 flex items-center justify-center rounded-full hover:bg-pri-d/[0.075] dark:hover:bg-pri/[0.075] transition-colors cursor-pointer">
        <FontAwesomeIcon icon={faXmark} />
      </div>

      <span className="relative flex items-center gap-x-1">
        <span className="w-10 h-[1px] bg-gray-300 dark:bg-zinc-600 rounded-lg" />
        <span className="text-xs text-gray-400 dark:text-zinc-500">
          for {notification?.account}
        </span>
      </span>
      <div className="flex flex-row gap-3 pt-3 select-none pointer-events-none">
        <Link to={`/profile/${authorFromMesssage}`} className="select-none">
          <div
            className={`
              flex flex-col flex-wrap justify-center select-none rounded-full border-transparent cursor-pointer overflow-hidden h-14 w-14 border-2 hover:border-acc duration-150 pointer-events-none
            `}
          >
            <img
              className="h-full w-full object-cover select-none pointer-events-none	"
              src={getAvatar(authorFromMesssage, "small")}
              alt={`avatar of ${authorFromMesssage}`}
              title={`avatar of ${authorFromMesssage}`}
            />
          </div>
        </Link>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <DisplayName
              authorName={authorFromMesssage}
              name={authorFromMesssage}
            />
            <span className="text-sm">
              {notification.type === "vote" ? (
                <div className="flex flex-row justify-center items-center gap-2">
                  voted on your post
                  <div className="w-fit text-sm justify-end px-1.5 py-1 bg-green-500/[0.15] text-green-500 rounded-lg">
                    +{voteAmount}
                  </div>
                </div>
              ) : (
                cleanMessage
              )}
            </span>
          </div>
          <span className="text-pri/50 dark:text-pri-d/50 text-xs font-normal">
            {notification.date}
          </span>
        </div>
      </div>
    </div>
  );
}

