import { useCallback, useEffect, useState } from "react";
import { useOnRouteChange } from "./useOnRouteChange";
import type { AccountSettings } from "~/store/settings";

export function useRestoreTheme(settings: AccountSettings, appLoaded: boolean) {
  const [darkMode, setDarkMode] = useState<boolean>(false);

  const restoreTheme = useCallback(() => {
    let storage = null;

    if (typeof window !== "undefined") {
      storage = window?.localStorage?.getItem("account-settings");
    }

    if (!settings && !storage) return;

    const data: AccountSettings = storage ? JSON.parse(storage) : settings;

    if (!data) return;

    const html = document.getElementsByTagName("html")[0];

    if (data?.theme === "Light") {
      html.classList.remove("dark", "dim");
      setDarkMode(false);
    }

    if (data?.theme === "Dark") {
      html.classList.add("dark", "dim");
      setDarkMode(true);
    }

    if (data?.theme === "Dimmed") {
      html.classList.add("dark");
      setDarkMode(true);
    }

    html.classList.remove("fs-14", "fs-15", "fs-16", "fs-17", "fs-18");
    html.classList.add(`fs-${data.typography}`);

    html.classList.add(data?.color.toLowerCase());
    html.classList.forEach(c => {
      const colors = [
        "orange",
        "yellow",
        "blue",
        "pink",
        "purple",
        "green"
      ].filter(c => c !== data?.color.toLowerCase());

      if (colors.includes(c)) {
        html.classList.remove(c);
      }
    });
  }, [settings]);

  useEffect(() => {
    if (typeof document === "undefined") return;

    restoreTheme();
  }, [restoreTheme, appLoaded]);

  useOnRouteChange(() => restoreTheme());

  return darkMode;
}
