import React, { useState } from "react";
import { useNavigate } from "@remix-run/react";

interface Props {
  communityNameForPublish?: string;
  setIsOpen: (isOpen: boolean) => void;
}

export default function PublishDropdownButtonContent({
  communityNameForPublish,
  setIsOpen
}: Props) {
  const navigate = useNavigate();
  return (
    <ul className="flex flex-col text-sm">
      <li>
        <button
          onClick={() => {
            navigate(
              `/publish${
                communityNameForPublish
                  ? `?community=${communityNameForPublish}`
                  : ""
              }`
            );
            setIsOpen(false);
          }}
          className="w-full px-4 py-2 disabled:opacity-60  text-left  hover:bg-pri/50 dark:hover:bg-pri-d/50 rounded-xl"
        >
          Create a Post
        </button>
      </li>
      <li>
        <button
          onClick={() => { 
            navigate("/publish/drafts");
            setIsOpen(false);
          }}
          className="w-full px-4 py-2 disabled:opacity-60  text-left  hover:bg-pri/50 dark:hover:bg-pri-d/50 rounded-xl"
        >
          View Drafts
        </button>
      </li>
      <li>
        <button
          onClick={() => { 
            navigate("/publish/templates");
            setIsOpen(false);
          }}
          className="w-full px-4 py-2 disabled:opacity-60  text-left  hover:bg-pri/50 dark:hover:bg-pri-d/50 rounded-xl"
        >
          View Templates
        </button>
      </li>
      <li>
        <button
          onClick={() => { 
            navigate("/publish/scheduling");
            setIsOpen(false);
          }}
          className="w-full px-4 py-2 disabled:opacity-60  text-left  hover:bg-pri/50 dark:hover:bg-pri-d/50 rounded-xl"
        >
          View Scheduled Posts
        </button>
      </li>
    </ul>
  );
}
