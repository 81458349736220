import { useEffect } from "react";
import moment from "moment";
import { useAppStore } from "~/store";
import { readLocalStorageAccounts } from "~/routes/login";
import {
  type AuthorPerm,
  type ParsedHiveNotification,
  fetchContent,
  fetchNotifications
} from "~/utils/hive";
import { useNavigate } from "@remix-run/react";
import { toast } from "react-toastify";
import { LiveNotification } from "~/components/layout/LiveNotification";

export function useLiveNotifications() {
  const [activeAccount, darkMode] = useAppStore(store => [
    store.account.activeAccount,
    store.settings.dark
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    let toastedNotifications: number[] = [];
    const currentTime = moment.now();

    const liveNotifications = setInterval(() => {
      void (async function () {
        if (!activeAccount?.name) return;

        const localStorageAccounts = readLocalStorageAccounts();

        localStorageAccounts.push(activeAccount?.name);

        const allAccountNotifications = await localStorageAccounts.map(
          async (accountName: string) => {
            const notificationsUnwrapped = await fetchNotifications(
              accountName,
              3,
              null
            );

            return notificationsUnwrapped.flat().map(notificationData => {
              return {
                ...notificationData,
                account: accountName
              };
            });
          }
        );

        const lastNotifications = await (
          await Promise.all(allAccountNotifications)
        ).flat();

        lastNotifications
          .filter((notification: ParsedHiveNotification) => {
            const notificationTime = moment(notification.date).utc(true);
            if (notificationTime.diff(currentTime) > 0) return notification;
          })
          .map((notification: ParsedHiveNotification) => {
            const timeElapsed = moment(notification.date).utc(true).fromNow();

            const isToasted = toastedNotifications?.includes(notification.id);
            if (!isToasted) {
              toastedNotifications = [...toastedNotifications, notification.id];
              return {
                ...notification,
                date: timeElapsed
              };
            }
          })
          .map(notification => {
            if (notification?.msg === undefined) return;

            const authorFromMesssage =
              notification?.msg?.split(" ").at(0)?.replaceAll("@", "") || "";

            const cleanMessage = notification?.msg.replaceAll(
              `@${authorFromMesssage}`,
              ""
            );

            let voteAmount = "0";

            if (notification.type === "vote") {
              const splitFromVoteMessage = notification.msg.split(
                "voted on your post "
              );
              const voter = splitFromVoteMessage[0]
                .replace("@", "")
                .replace(" ", "");
              voteAmount = splitFromVoteMessage[1]
                .replace("(", "")
                .replace(")", "");
            }

            const [author, permlink] = notification.url
              .replaceAll("@", "")
              .split("/");

            let notificationContent = {};

            const mouseEnter = async () => {
              notificationContent = await fetchContent({
                author,
                permlink
              } as AuthorPerm);
              navigate(
                notificationContent?.root_author === "leothreads"
                  ? `/threads/view/${author.replace("@", "")}/${permlink}`
                  : `/${author}/${permlink}`
              );
            };

            toast(
              <LiveNotification
                notification={notification}
                authorFromMesssage={authorFromMesssage}
                voteAmount={voteAmount}
                cleanMessage={cleanMessage}
                mouseEnter={mouseEnter}
              />,
              {
                theme: darkMode ? "dark" : "light",
                className:
                  "bg-pri dark:bg-pri-d border border-pri dark:border-pri-d transition-all duration-150 hover:brightness-80 hover:cursor-pointer shadow-[0_0_12px_3px_rgb(255_255_255_/_12%)]",
                position: "bottom-right",
                autoClose: 5500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
              }
            );
          });
      })();

      return () => clearInterval(liveNotifications);
    }, 6500);
  }, []);
}

